.stores {
  &-links {
    display: flex;
    align-items: center;
    gap: var(--space-2xs);
  }
}

.icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: var(--color-background-secondary);

  > img {
    width: 20px;
    height: auto;
  }
}
