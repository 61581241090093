@import '@carnica/themes/styles/media/media.pcss';

.wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-radius: 20px;
  gap: 24px;

  @media (width >= 800px) {
    max-width: 301px;
    gap: 55px;
    height: fit-content;
    background: var(--color-background-secondary);
  }

  @media (--desktop) {
    gap: 0;
    height: 302px;
    background-color: var(--color-background-secondary);
  }

  .button {
    text-align: center;
  }

  .text-centered {
    text-align: center;
  }
}
