@import '@carnica/themes/styles/media/media.pcss';

.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 136px;
  min-height: 44px;

  @media (--tablet) {
    max-width: 176px;
  }
}

.old-price {
  text-decoration: line-through;
}
