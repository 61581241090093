@import '@carnica/themes/styles/media/media.pcss';

.content {
  &-item {
    scroll-snap-align: center;
    width: 280px;
    height: 280px;
    transition: transform 0.6s ease-in-out;

    @media (--not-touchscreen) {
      &:hover {
        transform: scale(1.02);
      }

      &:not(:hover) {
        transition: transform 0.3s ease-in-out;
        transform: scale(1);
      }
    }
  }

  &-container {
    padding-right: 40px;
    padding-left: 40px;
    display: flex;
    gap: var(--space-m);
    overflow: auto hidden;
    scroll-snap-type: x mandatory;

    @media (width > 1025px) {
      padding-right: 0;
      padding-left: 0;
      display: grid;
      overflow: unset;
      grid-template-columns: repeat(3, 1fr);
      max-width: 1100px;
      scroll-snap-type: none;
      scroll-padding: 0;
      gap: 60px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
