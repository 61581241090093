@import '@carnica/themes/styles/media/media.pcss';

.input {
  margin-bottom: 24px;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: var(--color-background-tertiary-invert) !important;

  input {
    /* Fix later Carnica SearchField. Фикс для старых страниц сайта. Стили формы перебивают стили carnica */
    font-family:
      BeelineSans,
      -apple-system,
      sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: var(--color-text-primary-invert) !important;
    border: none !important;
    outline: none !important;
    background-color: var(--color-background-tertiary-invert) !important;
  }

  & span,
  & button {
    & > img,
    & > svg,
    img,
    svg {
      --icon-filter: brightness(0) saturate(100%) invert(73%) sepia(6%) saturate(687%)
        hue-rotate(182deg) brightness(88%) contrast(86%) !important;
    }
  }
}

.input-block {
  position: relative;
  margin-top: 24px;
  padding: 0 20px;

  @media (width > 480px) and (width <= 768px) {
    padding: 0 40px;
  }

  @media (--tablet) {
    padding: 0;
  }

  @media (--desktop) {
    margin-top: 32px;
  }

  & > div:first-child {
    padding: 0;
  }
}
