@import '@carnica/themes/styles/media/media.pcss';

.carousel {
  &-swiper {
    overflow: hidden;
    width: 100%;
    border-radius: 24px !important;
    background-color: transparent !important;

    @media (width > 480px) {
      background-color: var(--color-background-secondary);
    }

    @media (width > 800px) {
      margin-right: 0;
      margin-left: 0;
      width: 100% !important;
      border-radius: 0;
    }
  }

  &-slide {
    border-radius: 0 !important;
    background-color: transparent;
  }
}

.group-slide {
  border-radius: 0;
  background-color: transparent;
}
