@import '@carnica/themes/styles/media/media.pcss';
@import '@carnica/themes/mixins/scrollbar.pcss';

.modal-content {
  @mixin scrollbar;
  position: absolute;
  padding: 96px 4px 0;
  overflow: hidden auto;
  align-self: flex-start;
  box-sizing: border-box;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  border: none;
  background-color: transparent;
  pointer-events: auto;

  @media (width > 480px) {
    padding: 96px 80px 0;
  }

  @media (--desktop) {
    padding: 0;
  }

  &_fullWidthContent {
    width: 100vw;
  }

  &_animate {
    &_exited,
    &_exiting {
      opacity: 0;
      pointer-events: none;
    }

    &_entered,
    &_entering {
      opacity: 1;
    }

    &_entered,
    &_entering,
    &_exiting,
    &_exited {
      transition: opacity 0.3s ease-in-out;
    }
  }

  &__action-bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    pointer-events: none;

    @media (--desktop) {
      padding: 16px 40px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
}
