@import '@carnica/themes/styles/media/media.pcss';

.wrapper {
  z-index: 20;
  display: flex;
  justify-content: center;
  transition: unset;

  @media (--desktop) {
    left: 50%;
    transform: translate(-50%, 0);
  }
}
