.container {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.info {
  display: flex;
  align-items: center;
  max-width: 230px;
  gap: var(--space-xs);
}

.block {
  display: flex;
  flex-direction: column;
  gap: var(--space-3xs);
}
