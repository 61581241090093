@import '@carnica/themes/styles/media/media.pcss';

.content {
  margin-left: 24px;
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 200px;
  gap: var(--space-2xl);

  @media (--tablet) {
    margin-left: 10%;
    padding-top: 0;
    width: 250px;
  }

  @media (width >= 960px) {
    margin-top: 100px;
    margin-left: 0;
    align-items: flex-start;
    width: 240px;
    gap: 0;
  }

  @media (width > 1024px) {
    width: 280px;
  }

  @media (width >= 1100px) {
    margin-top: 194px;
    margin-left: 0;
  }
}

.title {
  line-height: 36px;
  color: var(--color-text-primary-invert);
  @media (--tablet) {
    color: var(--color-text-primary);
  }
  @media (width >= 960px) {
    margin-top: 40px;
  }
}

.description {
  max-width: 195px;
  line-height: 20px;
  color: var(--color-text-secondary-invert);

  @media (--tablet) {
    max-width: 100%;
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-regular);
    line-height: 26px;
    color: var(--color-text-secondary);
  }
  @media (width >= 960px) {
    margin-top: 20px;
  }
}

.store-links {
  display: flex;
  gap: var(--space-2xs);
}

.icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: var(--color-background-tertiary-invert);

  > img {
    width: 20px;
    height: auto;
  }
}

.icon-ios {
  width: 150px;
  border-radius: 24px;

  > img {
    width: auto;
  }
}

.icon {
  width: 64px;
  height: 64px;
}
