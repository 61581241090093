@import '@carnica/themes/styles/media/media.pcss';

.bottom-links {
  &__list {
    margin: 0 0 40px;
    padding: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 12px;
  }

  &__link {
    &:hover {
      color: var(--color-brand-primary);
    }
  }

  .stores {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__title {
      margin-bottom: 12px;
    }
  }
}

.action-button {
  background-color: #28303f;
  pointer-events: auto;

  &:active,
  &:focus {
    background-color: var(--color-background-tertiary-invert);
  }

  & span,
  & button,
  & > span,
  & > button {
    & > img,
    & > svg,
    img,
    svg {
      --icon-filter: brightness(0) saturate(100%) invert(98%) sepia(91%) saturate(1%)
        hue-rotate(175deg) brightness(101%) contrast(101%) !important;
    }
  }
}

@media (--not-touchscreen) {
  .action-button {
    &:hover {
      background-color: #222731;
    }
  }
}
