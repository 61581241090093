@import '@carnica/themes/styles/media/media.pcss';

.top-card {
  z-index: 1;
  padding: 40px 24px;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  height: fit-content;
  border-radius: 24px;
  background-color: var(--color-background-secondary);
  box-shadow: 0 10px 40px 0 rgb(40 48 63 / 8%);
  gap: 10px;

  @media (--desktop) {
    padding: 40px 40px 56px;
    background-color: var(--color-background-primary);
    box-shadow: none;
  }
}

.title {
  margin: auto;
  display: inline-flex;
  max-width: 311px;
  text-align: center;

  @media (--desktop) {
    margin: unset;
    max-width: unset;
    text-align: left;
  }
}

.form {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  gap: 38px;

  @media (--desktop) {
    justify-content: start;
    gap: 40px;
  }
}

.input-container {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  gap: 60px;

  @media (--desktop) {
    gap: 24px;
  }
}

.button-container {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
