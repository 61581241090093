.component {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.partner-logo {
  position: absolute;
  top: 0;
  right: 0;
}
