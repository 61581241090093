@import '@carnica/themes/styles/media/media.pcss';

.block {
  position: relative;
  margin-left: -4px;
  padding-top: 48px;
  padding-bottom: 48px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: calc(100% + 8px);
  gap: var(--space-4xl);

  @media (--breakpoint-768) {
    margin-left: -40px;
    width: calc(100% + 80px);
  }

  @media (width > 1025px) {
    margin-left: 0;
    padding: 40px;
    width: 100%;
  }

  @media (width > 1100px) {
    padding: 60px 120px 80px;
    gap: 0;
  }
}

.content {
  width: 100%;
}

.heading {
  &-title {
    margin: 0 auto;
    max-width: 90%;
    text-align: center;

    @media (width > 481px) {
      margin: 0;
      padding-left: 40px;
      max-width: 100%;
      font-size: var(--font-size-xl);
      line-height: 52px;
      text-align: left;
    }

    @media (--breakpoint-768) {
      padding-left: 80px;
    }

    @media (width > 1025px) {
      padding-bottom: 55px;
      padding-left: 0;
      font-size: var(--font-size-xl);
      line-height: 56px;
      text-align: left;
    }
  }
}

.actions {
  display: flex;
  justify-content: center;

  @media (--tablet) {
    display: none;
  }
}
