@import '@carnica/themes/styles/media/media.pcss';

.title {
  text-align: center;

  @media (width >= 480px) {
    text-align: left;
  }
  @media (width > 1100px) {
    line-height: 1;
  }
}
