@import '@carnica/themes/styles/media/media.pcss';

@custom-media --medium-screen (width > 480px) and (width <= 1024px);

.app {
  position: relative;
  padding: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 0 0 24px 24px;

  @media (--medium-screen) {
    top: -40px;
    margin: 0 auto;
    padding: 60px 24px 24px;
    width: 100%;
    max-width: 400px;
    background-color: var(--color-background-secondary);
  }

  @media (width > 1024px) {
    top: -40px;
    padding: 60px 24px 24px;
    border: 2px solid #f0f3f5;
  }

  .title {
    display: flex;
    justify-content: center;
    width: fit-content;
    @media (--medium-screen) {
      text-align: center;
    }

    @media (--desktop) {
      margin-right: auto;
    }
  }

  &__stores {
    width: 100%;
    @media (--medium-screen) {
      margin: 0 auto;
      width: fit-content;
    }
  }

  &__install {
    margin-top: 20px;
    display: flex;
    width: 100%;

    &-text {
      @media (--medium-screen) {
        display: none;
      }
    }

    &-qr {
      margin-right: 24px;
      user-select: none;
      @media (--medium-screen) {
        display: none !important;
      }
    }
  }
}
