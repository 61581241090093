@import '@carnica/themes/styles/media/media.pcss';

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  border-radius: 20px;
  background-color: var(--color-background-secondary);
  box-shadow: 0 0 40px 0 #0000000d;

  @media (width >= 800px) {
    width: 133px;
    height: 133px;
  }

  @media (--desktop) {
    width: 150px;
    height: 150px;
  }

  .image {
    max-width: 100px;
    max-height: 100px;
  }
}
