@import '@carnica/themes/styles/media/media.pcss';

.wrapper {
  margin: 0;
  padding-top: 60px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 12px;

  @media (width >= 480px) {
    padding: 40px 40px 0;
    text-align: left;
  }

  @media (width > 1024px) {
    padding: 78px 20px 40px;
    align-items: baseline;
    flex-direction: row;
    justify-content: space-between;
    text-align: unset;
  }

  @media (width >= 1100px) {
    padding: 80px 66px 40px;
  }

  @media (--breakpoint-1280) {
    padding: 80px 120px 0;
  }
}
