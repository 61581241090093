@import '@carnica/themes/styles/media/media.pcss';

.app {
  position: relative;
  margin: 4px 8px;
  display: flex;
  overflow: hidden;
  align-items: center;
  width: calc(100% - 16px);
  max-width: 1200px;
  height: 600px;
  border-radius: 24px;
  background-color: var(--color-background-primary-invert);

  @media (width > 480px) {
    margin: 40px 0;
    width: 100%;
  }

  @media (--tablet) {
    margin: 0 auto;
    width: 100%;
    height: 500px;
    border-radius: 0;
    background-color: transparent;
  }

  @media (width >= 960px) {
    padding: 30px;
    align-items: flex-start;
    height: 600px;
  }

  @media (width >= 1100px) {
    padding-right: 120px;
    padding-left: 120px;
  }

  &-store {
    display: none;
    @media (width >= 960px) {
      margin-top: 100px;
      margin-left: auto;
      display: block;
    }

    @media (width >= 1100px) {
      margin-top: 194px;
    }
  }

  &-phone {
    position: absolute;
    top: 5%;
    right: -200px;
    height: 90% !important;

    @media (width >= 360px) {
      right: -150px;
    }

    @media (width >= 430px) {
      right: -120px;
    }

    @media (--tablet) {
      inset: auto 10% -15% auto;
      height: 100% !important;
    }
    @media (width >= 960px) {
      inset: 0 0 -27%;
      margin: auto;
      height: 100% !important;
    }
  }
}
