@import '@carnica/themes/styles/media/media.pcss';

.wrapper {
  padding: 20px 0 var(--space-2xl);
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  gap: 20px;

  @media (--tablet) {
    padding: var(--space-xl) 0 0;
  }
  @media (width >= 1025px) {
    align-items: flex-start;
  }
}

.block {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
  justify-content: center;

  @media (width >= 1025px) {
    justify-content: flex-start;
  }

  @media (--tablet) {
    gap: 4px;
  }
}

.text {
  text-align: center;
  @media (--tablet) {
    text-align: left;
  }
}
