@import '@carnica/themes/styles/media/media.pcss';

.banner {
  padding: 80px 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 600px;
  border-radius: 0;
  background-repeat: no-repeat;
  background-size: cover;

  @media (width >= 801px) {
    padding: 60px 60px 40px;
    height: 440px;
  }

  @media (--breakpoint-1280) {
    padding: 60px 60px 32px;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: var(--space-m);
  }

  &__footer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    gap: var(--space-m);
  }
}
