.sticky-wrapper {
  position: absolute;
  top: 0;

  @media (width >= 768px) {
    left: 35%;
  }

  @media (width >= 960px) {
    left: 20%;
  }
}
