.wrapper {
  position: relative;
  display: flex;
  align-items: center;

  .card-transform {
    &:nth-child(1) {
      z-index: 0;
      transform: scale(0.825) rotate(-5deg) translate(40px, 5px);
    }

    &:nth-child(3) {
      z-index: 0;
      transform: scale(0.822) rotate(5deg) translate(-40px, 5px);
    }

    &:nth-child(2) {
      z-index: 1;
    }
  }
}
