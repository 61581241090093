@import '@carnica/themes/styles/media/media.pcss';

.stores {
  display: flex;
  gap: 4px;
  justify-content: center;

  @media (--desktop) {
    justify-content: flex-start;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 200px;
    background-color: var(--color-background-secondary-invert);
    transition: background-color 0.15s ease-in-out;

    @media (width > 1024px) {
      background-color: var(--color-background-primary);
    }

    @media (--not-touchscreen) {
      &:hover {
        background-color: var(--color-background-tertiary);
      }
    }

    &-img {
      width: 20px;
      height: 20px !important;
    }

    &-img-full {
      width: 100%;
      max-width: 95px;
      height: auto;
      max-height: 25px;
    }

    &-full-width {
      padding: 12px 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 40px;
      background: var(--color-background-tertiary-invert);
    }

    svg {
      width: 20px;
    }
  }
}
