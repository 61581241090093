@import '@carnica/themes/styles/media/media.pcss';

@custom-media --between-1440-1920 (width >= 1440px) and (width < 1920px);

.carousel {
  position: relative;
  margin-right: 8px;
  margin-left: 8px;
  height: 540px;
  transition: opacity 0.6s ease-in-out;

  @media (width > 800px) {
    height: 460px;
  }

  @media (width >= 1440px) {
    height: 520px;
  }

  @media (--breakpoint-1920) {
    height: 640px;
  }

  @media (width > 480px) {
    margin-right: 0;
    margin-left: 0;
  }

  &.hidden {
    @media (width > 800px) {
      .carousel-swiper {
        display: none;
      }
    }
  }

  &-slide {
    overflow: hidden;
    border-radius: 0;
    background-color: transparent;

    &-prev {
      .carousel-slide-content {
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
      }
    }
  }

  &-swiper {
    overflow: hidden;
    border-radius: 24px;

    @media (width > 480px) {
      border-radius: 0;
    }
  }
}

.tag {
  @media (--breakpoint-1920) {
    margin-bottom: 16px;
  }
}

.heading {
  @media (--between-1440-1920) {
    font-size: var(--font-size-xl);
    line-height: 56px;
  }
}

.subtitle {
  @media (--between-1440-1920) {
    font-size: var(--font-size-s);
    line-height: 26px;
  }
}

.button {
  @media (width > 1024px) {
    margin-top: 16px;
  }
}
