@import '@carnica/themes/styles/media/media.pcss';

.carousel {
  &-slide {
    width: 280px !important;
    height: 280px !important;

    @media (--breakpoint-1280) {
      width: 304px !important;
      height: 304px !important;
    }
  }

  &-container {
    position: relative;
    overflow: hidden;

    @media (--desktop) {
      overflow: unset;
    }

    @media (hover: hover) {
      &:hover {
        .arrow-button {
          opacity: 1 !important;
          pointer-events: auto !important;
        }
      }
    }
  }

  &-arrow {
    position: absolute;
    z-index: 10;
    display: flex;
    align-items: center;
    width: 50px;
    height: 100%;
    @media (--breakpoint-1280) {
      width: 120px;

      &.arrow-prev {
        top: 0;
        left: 0;
        background: linear-gradient(90deg, #fff 0%, rgb(255 255 255 / 0%) 100%);
      }
    }

    .arrow-button {
      opacity: 0;
      transition: opacity 0.5s;
      pointer-events: none;
    }

    .arrow-next {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &__button {
        margin-right: 40px;
        display: none;
        @media (--breakpoint-1280) {
          display: block;
        }
      }
    }

    .arrow-prev {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &__button {
        margin-left: 40px;
        display: none;
        @media (--breakpoint-1280) {
          display: block;
        }
      }
    }

    &.arrow-next {
      top: 0;
      right: 0;
      background: linear-gradient(270deg, #fff 0%, rgb(255 255 255 / 0%) 100%);
    }
  }

  &-swiper {
    padding-right: 24px !important;
    padding-left: 24px !important;
    overflow: unset !important;

    @media (width > 480px) {
      padding-right: 40px !important;
      padding-left: 40px !important;
    }

    @media (width > 1024px) {
      padding-right: 25px !important;
      padding-left: 20px !important;
      overflow: unset;
    }

    @media (width >= 1100px) {
      padding-right: 66px !important;
      padding-left: 66px !important;
    }

    @media (--breakpoint-1280) {
      padding-right: 120px !important;
      padding-left: 120px !important;
    }
  }
}
