@import '@carnica/themes/styles/media/media.pcss';

.navigation {
  margin: 0;
  padding-right: 12px;
  padding-left: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  gap: 8px 24px;

  @media (width >= 480px) {
    padding-left: 0;
    justify-content: flex-start;
  }

  @media (width > 1024px) {
    margin: 0;
    padding: 0;
    gap: 25px;
  }

  &__link {
    text-decoration: none;

    @media (--not-touchscreen) {
      &:hover {
        color: var(--color-text-primary);
      }
    }
  }
}
