.options {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);

  &__group {
    img {
      max-width: initial;
    }
  }
}
