@import '@carnica/themes/styles/media/media.pcss';

.wrapper {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;

  @media (--desktop) {
    max-width: 100%;
  }
}

.content {
  position: relative;
  margin-top: -40px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border-radius: 20px;
  background-color: var(--color-background-secondary-invert);
  background-repeat: no-repeat;
  background-position: right;
  gap: var(--space-l);

  &--no-offset {
    margin-top: 0;
  }
}
