@import '@carnica/themes/styles/media/media.pcss';

.block {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  border-radius: 24px;
  background-color: var(--color-background-secondary);
  gap: var(--space-2xl);

  @media (--desktop) {
    border-radius: 0;
    background-color: transparent;
  }
}

.heading {
  &-title {
    text-align: center;
    @media (width > 480px) {
      padding-left: 40px;
      font-size: var(--font-size-l);
      line-height: 36px;
      text-align: left;
    }

    @media (width > 1024px) {
      padding-left: 20px;
    }

    @media (width >= 1100px) {
      padding-left: 66px;
    }

    @media (--breakpoint-1280) {
      padding-left: 120px;
    }
  }
}

.tabs {
  overflow-x: auto;
  scrollbar-width: none;
  width: 100%;

  &::-webkit-scrollbar {
    display: none; /* Скрываем скролл для Chrome, Safari */
  }

  &-group {
    padding-right: 24px;
    padding-left: 24px;
    width: 750px;

    @media (width > 480px) {
      padding-right: 40px;
      padding-left: 40px;
    }

    @media (width > 1024px) {
      padding-right: 25px;
      padding-left: 20px;
    }

    @media (width >= 1100px) {
      padding-right: 66px;
      padding-left: 66px;
      width: 1000px;
    }

    @media (--breakpoint-1280) {
      padding-right: 120px;
      padding-left: 120px;
    }
  }
}
