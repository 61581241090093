@import '@carnica/themes/styles/media/media.pcss';
@custom-media --medium-screen (width > 480px) and (width <= 1024px);

.wrapper {
  position: relative;
  z-index: 1;
  padding: 24px 24px 76px;
  border-radius: 24px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
  box-shadow: 0 10px 40px 0 #28303f14;

  @media (--medium-screen) {
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
  }

  @media (width > 1024px) {
    padding: 40px 40px 76px;
    background-color: var(--color-background-primary);
    box-shadow: none;
  }

  .title {
    margin-top: 40px;
    margin-bottom: 16px;
  }

  .button {
    margin-top: 32px;
  }
}
