@import '@carnica/themes/styles/media/media.pcss';

.wrapper {
  position: relative;
  z-index: 2;
  padding-bottom: 80px;
  background-color: var(--color-background-primary);

  @media (width > 480px) {
    padding-bottom: 41px;
  }

  @media (width > 767px) {
    padding-bottom: 1px;
  }

  @media (width > 800px) {
    padding-bottom: 40px;
  }

  @media (--desktop) {
    transition: transform 0.45s linear;
  }
}
