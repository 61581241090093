@import '@carnica/themes/styles/media/media.pcss';

.wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 20px;
  background-color: var(--color-background-secondary);
  gap: 40px;

  @media (width >= 560px) {
    background-color: var(--color-background-primary);
  }

  @media (--desktop), (width >= 800px) {
    padding: 40px;
    align-items: normal;
    flex-direction: row;
    justify-content: space-between;
    height: 280px;
    text-align: left;
    transition: transform 0.6s ease-in-out;
    cursor: pointer;

    @media (--not-touchscreen) {
      &:hover {
        transform: scale(1.02);
      }

      &:not(:hover) {
        transition: transform 0.3s ease-in-out;
        transform: scale(1);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .nowrap {
    white-space: nowrap;
  }
}
