@import '@carnica/themes/styles/media/media.pcss';

.wrapper {
  position: relative;
  width: 100%;
  border-radius: 24px;
  background-color: #fff;

  @media (width >= 800px) {
    height: fit-content;
    background-color: var(--color-background-primary);
  }

  @media (--desktop) {
    background-color: var(--color-background-primary);
  }
}

.content-min-tablet {
  width: 100%;

  @media (width >= 800px) {
    padding: 12px;
  }
}
