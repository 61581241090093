@import '@carnica/themes/styles/media/media.pcss';

.banner {
  display: flex;
  width: 100%;
  height: 460px;
  background-repeat: no-repeat;
  background-size: cover;

  @media (width >= 1440px) {
    height: 520px;
  }

  @media (--breakpoint-1920) {
    height: 640px;
  }

  &__container {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (width > 1024px) {
      max-width: 1000px;
    }

    @media (--breakpoint-1280) {
      max-width: 1200px;
      height: 100%;
    }
  }

  &__content {
    padding-top: 70px;
    padding-left: 40px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    gap: var(--space-m);

    @media (width >= 1440px) {
      padding-top: 80px;
      padding-left: 0;
    }

    @media (--breakpoint-1920) {
      padding-top: 120px;
    }
  }

  &__legal {
    position: absolute;
    bottom: 90px;
    left: 40px;

    @media (width >= 1440px) {
      bottom: 70px;
      left: 0;
    }

    @media (--breakpoint-1920) {
      bottom: 140px;
    }
  }

  &__image {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}
