@import 'styles/media.pcss';

.group {
  display: flex;
  flex-flow: row wrap;
  gap: var(--space-3xs);
  width: 100%;

  &-item {
    transition: transform 0.4s ease-out;
    transform: scale(1);

    &:hover {
      transition: transform 0.4s ease-in-out;
      transform: scale(1.01);
    }
  }
}
