@import '@carnica/themes/styles/media/media.pcss';

.section {
  padding: var(--space-3xl) 0 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: max-content;
  border-radius: 20px;
  background-color: var(--color-background-secondary);

  @media (width >= 481px) {
    padding: var(--space-3xl);
    background-color: var(--color-background-primary);
  }

  @media (width >= 1025px) {
    align-items: flex-start;
  }
}
