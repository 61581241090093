@import 'styles/media.pcss';

.container {
  position: relative;
}

.content {
  position: relative;
  margin-top: -40px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border-radius: 20px;
  background-color: var(--color-background-secondary-invert);
  background-repeat: no-repeat;
  background-position: top right;
  gap: var(--space-l);

  &--no-offset {
    margin-top: 0;
  }
}
