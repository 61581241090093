@import '@carnica/themes/styles/media/media.pcss';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  height: 100%;
  text-decoration: none;
  border-radius: 24px;
  transition: transform 0.6s ease-in-out;
  cursor: pointer;
  gap: var(--space-3xl);

  @media (--not-touchscreen) {
    &:hover {
      transform: scale(1.04);
    }

    &:not(:hover) {
      transition: transform 0.3s ease-in-out;
      transform: scale(1);
    }
  }

  &.dark {
    background-color: var(--color-background-tertiary-invert);
  }

  &.light {
    background-color: var(--color-background-primary);
  }

  &.white {
    background-color: var(--color-background-secondary);
  }
}

.header {
  position: absolute;
  top: 50%;
  left: 0;
  padding-right: 24px;
  padding-left: 24px;
  transform: translateY(-50%);

  &.dark {
    path {
      fill: var(--color-text-primary-invert);
    }
  }

  &.light,
  &.white {
    path {
      fill: var(--color-text-primary);
    }
  }
}

.label {
  &.dark {
    color: var(--color-text-primary-invert);
  }

  &.light,
  &.white {
    color: var(--color-text-primary);
  }
}

.link {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  line-height: 1.2em;

  &.light,
  &.white {
    color: var(--color-text-secondary);

    & > svg {
      fill: var(--color-text-secondary);
    }
  }
}

.icon {
  width: 24px;
  height: 24px;
  object-fit: cover;

  &-wrapper {
    padding: 24px;

    &.dark {
      path {
        fill: var(--color-text-primary-invert);
      }
    }

    &.light,
    &.white {
      path {
        fill: var(--color-text-primary);
      }
    }
  }
}
