@import '@carnica/themes/styles/media/media.pcss';
@custom-media --medium-screen (width > 480px) and (width <= 1024px);

.wrapper {
  height: calc(100% - 75px);
  border-radius: 24px;
  background-color: #fff;

  @media (--medium-screen) {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: var(--color-background-primary);
  }

  @media (width > 1024px) {
    width: 450px;
    border-radius: 0;
  }
}
