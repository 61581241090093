@import '@carnica/themes/styles/media/media.pcss';

.card {
  position: relative;
  padding: 20px;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 118px;
  border-radius: 20px;
  background-color: var(--color-background-primary);
  cursor: pointer;
  will-change: transform, opacity;

  @media (width > 1024px) {
    padding: 0;
    width: 221px;
    height: 180px;
  }
}

.icon {
  position: absolute;
  left: 20px;
  width: 24px;
  height: 24px;
  transition:
    opacity 0.5s ease-in-out,
    transform 0.5s ease-in-out;
  transform: translateY(20px);

  &.hover {
    opacity: 0;
    transform: translateY(0);
  }
}

.content {
  position: absolute;
  left: 20px;
  z-index: 1;
  width: 85%;
  transition: transform 0.5s ease-in-out;
}

.background-image {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 118px !important;
  opacity: 0;
  transition:
    opacity 0.5s ease-in-out,
    transform 0.5s ease-in-out;
  transform: translateY(5px);

  @media (width > 1024px) {
    height: auto !important;
  }

  &.hover {
    opacity: 1;
    transform: translateY(0);
  }
}

.subtitle {
  position: absolute;
  margin-top: 8px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &.hover {
    opacity: 1;
  }
}
