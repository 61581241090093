@import '@carnica/themes/styles/media/media.pcss';

.wrapper {
  padding: 24px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 350px; /* По умолчанию высота для <=480px */
  text-decoration: none;
  border-radius: 24px;
  background-color: var(--color-background-primary);
  background-repeat: no-repeat;
  transition: transform 0.6s ease-in-out;
  cursor: pointer;

  @media (--desktop) {
    padding: 32px;
  }

  @media (--not-touchscreen) {
    &:hover {
      transform: scale(1.02);
    }

    &:not(:hover) {
      transition: transform 0.3s ease-in-out;
      transform: scale(1);
    }
  }

  /* Для разрешений >=481px и <=800px */
  @media (width >= 481px) and (width <= 800px) {
    .title {
      font-size: 32px;
      line-height: 36px;
    }

    .subtitle {
      font-size: 20px;
      line-height: 26px;
    }
  }

  /* Для разрешений >=801px и <=1024px */
  @media (width >= 801px) and (width <= 1024px) {
    height: 365px; /* Изменение высоты */

    .title {
      font-size: 32px;
      line-height: 36px;
    }

    .subtitle {
      font-size: 20px;
      line-height: 26px;
    }
  }

  /* Для разрешений >1024px */
  @media (width > 1024px) {
    height: 420px; /* Изменение высоты */

    .title {
      font-size: 32px;
      line-height: 36px;
    }

    .subtitle {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .title {
    margin-bottom: 12px;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 100px;
    background-color: var(--color-background-tertiary);
  }
}
