@import '@carnica/themes/styles/media/media.pcss';

.wrapper {
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 24px;
  background-color: #fff;

  @media (--desktop) {
    padding: 0;
    background-color: unset;
    gap: 48px;
  }

  .title {
    padding: 40px 20px 32px;
    width: 100%;
    text-align: center;

    @media (width > 800px) {
      padding-right: 0;
      padding-left: 0;
    }

    @media (--desktop) {
      padding-top: 0;
      padding-bottom: 0;
      text-align: left;
    }
  }

  .blocks {
    margin-bottom: 4px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;

    @media (width >= 960px) {
      flex-direction: row;
      flex-grow: 1;
      gap: 20px;
    }

    @media (width > 1024px) {
      gap: 60px;
    }
  }
}
