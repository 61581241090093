@import '@carnica/themes/styles/media/media.pcss';

.extra-wrapper {
  display: flex;
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  border-radius: 0;

  @media (width >= 610px) {
    padding: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background-color: var(--color-background-primary);
  }

  @media (--desktop) and (height >= 720px) {
    position: sticky;
    top: 136px;
  }
  @media (--desktop) {
    padding: 0;
    height: fit-content;
    border-radius: 0;
    background-color: var(--color-background-secondary);
  }
}

.wrapper {
  display: flex;
  align-self: stretch;
  flex: 1;
  flex-direction: column;

  @media (--tablet) {
    align-self: unset;
    max-width: 400px;
  }

  @media (--desktop) {
    align-self: stretch;
    width: unset;
    min-width: 450px;
    max-width: unset;
  }
}
