@import '@carnica/themes/styles/media/media.pcss';

.help-block {
  position: relative;
  padding: 60px 0 0;
  box-sizing: border-box;

  @media (width > 480px) and (width <= 768px) {
    padding: 20px 0 60px;
  }

  @media (--tablet) {
    padding: 20px 80px 160px;
  }

  @media (--desktop) {
    padding: 20px 120px 80px;
  }

  &__anchor {
    position: absolute;
    top: -80px;
  }

  &__icon {
    position: absolute;
    bottom: -10px;
    z-index: 3;
    display: block;
    width: 155px;
    transform: scaleX(-1);

    @media (--tablet) {
      right: 0;
    }

    @media (--desktop) {
      right: 0;
      transform: translateX(-80%) scaleX(-1);
    }
  }
}
