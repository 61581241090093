@import '@carnica/themes/styles/media/media.pcss';

.bottom-card {
  position: relative;
  margin-top: -40px;
  padding: 70px 24px 0;
  display: flex;
  overflow: hidden;
  align-self: stretch;
  flex-direction: column;
  height: 386px;
  border-radius: 24px;
  background-color: var(--color-background-secondary);

  @media (--desktop) {
    margin-top: -80px;
    padding: 108px 32px 28px;
    box-sizing: border-box;
    height: fit-content;
    border: 2px solid var(--color-background-primary);
  }
}

.description {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  gap: 32px;

  @media (--desktop) {
    justify-content: flex-start;
    max-width: 280px;
    gap: 24px;
  }
}

.animation-container {
  position: absolute;
  right: calc(50% - 120px);
  bottom: -106px;
  margin: auto;
  display: flex;

  @media (--desktop) {
    position: absolute;
    right: -88px;
    bottom: -88px;
    margin: unset;
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (--desktop) {
    gap: 8px;
  }

  .primary,
  .secondary {
    text-align: center;

    @media (--desktop) {
      text-align: left;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
