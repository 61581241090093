@import '@carnica/themes/styles/media/media.pcss';
@import '@carnica/themes/mixins/scrollbar.pcss';

.scrollable-result-block {
  @mixin scrollbar;

  margin-top: 16px;
  padding: 0 16px;
  overflow: auto;
  flex-grow: 1;
  box-sizing: border-box;

  @media (--tablet) {
    padding: 0 24px;
  }

  @media (--desktop) {
    margin-top: 0;
  }
}
