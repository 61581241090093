@import '@carnica/themes/styles/media/media.pcss';

.wrapper {
  padding: 24px 4px 4px;
  border-radius: 24px;
  background-color: var(--color-background-secondary);

  @media (width > 1024px) {
    padding: 40px 0 0;
    text-align: unset;
  }

  .title {
    text-align: center;
    @media (width > 1024px) {
      text-align: left;
    }
  }

  .subtitle {
    margin-top: 12px;
    text-align: center;
    @media (width > 1024px) {
      text-align: left;
    }
  }

  .cards {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    @media (width <= 1024px) {
      margin-right: auto;
      margin-left: auto;
      width: 100%;
      max-width: 400px;
    }

    @media (width > 1024px) {
      margin-top: 40px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      width: fit-content;
    }
  }
}
