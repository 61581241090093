@import '@carnica/themes/styles/media/media.pcss';

.content {
  position: relative;
  padding: 20px;
  width: 100%;
  height: 350px;
  border-radius: 20px;
  background-color: var(--color-background-primary);

  @media (width > 800px) {
    height: 330px;
  }

  @media (--desktop) {
    height: unset;
  }
}

.button {
  text-align: center;
}

.icon {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  width: 155px;
  transform: translateX(-50%);

  @media (width >= 800px) {
    left: 25%;
    width: 130px;
  }

  @media (width >= 960px) {
    left: 45%;
    width: 160px;
  }

  @media (--desktop) {
    bottom: 0;
    left: 50%;
    display: block;
    width: 200px;
    transform: translateX(-50%);
  }
}

.title {
  font-size: 24px;
  line-height: 24px;
  @media (width > 480px) {
    font-size: 32px;
    line-height: 36px;
  }
}

.subtitle {
  margin-top: 12px;
  margin-bottom: 32px;
  @media (width > 480px) {
    font-size: 20px;
    line-height: 26px;
  }
}
