@import 'styles/media.pcss';

.component {
  margin-bottom: var(--space-2xl);
  text-align: center;

  @media (--tablet) {
    margin-bottom: var(--space-l);
    text-align: left;
  }
}
