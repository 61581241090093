@import '@carnica/themes/styles/media/media.pcss';

.wrapper {
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;

  @media (width > 480px) {
    margin-bottom: 40px !important;
    padding-bottom: 80px;
    border-radius: 24px;
    background-color: var(--color-background-secondary);
    box-shadow: 0 24px 40px 0 #0000000d;
  }

  @media (width > 800px) {
    margin-bottom: 60px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);

  @media (width > 480px) {
    gap: var(--space-3xl);
  }

  @media (--desktop) {
    gap: 60px;
  }
}

.heading {
  padding-bottom: 17px;
  @media (width > 800px) {
    padding-bottom: 40px;
  }
}
