@import '@carnica/themes/styles/media/media.pcss';

.input {
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  height: 36px;
}

.button {
  display: flex;

  & > * {
    /* // из-за того, что в ките кнопки поехали  */
    justify-content: center;
  }
}

.inline-button {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--desktop) {
    justify-content: flex-start;
  }

  & > * {
    flex-grow: unset;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
  }
}
