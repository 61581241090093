@import 'styles/media.pcss';

.component {
  display: flex;
  align-items: center;
  max-width: 136px;
  min-height: 44px;

  @media (--tablet) {
    max-width: 176px;
  }
}
