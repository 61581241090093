@import '@carnica/themes/styles/media/media.pcss';

.section {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  border-radius: 24px;
  background-color: var(--color-background-secondary);
  gap: var(--space-2xl);

  @media (width >= 1025px) {
    border-radius: 0;
    background-color: transparent;
  }
}

.heading {
  display: flex;
  justify-content: center;

  @media (width >= 481px) {
    padding-right: 40px;
    padding-left: 40px;
    justify-content: space-between;
  }

  @media (width >= 1025px) {
    padding-right: 25px;
    padding-left: 20px;
  }

  @media (width >= 1100px) {
    padding-right: 66px;
    padding-left: 66px;
  }

  @media (--breakpoint-1280) {
    padding-right: 120px;
    padding-left: 120px;
  }

  &-title {
    text-align: center;
    @media (width >= 481px) {
      font-size: var(--font-size-l);
      line-height: 36px;
      text-align: left;
    }
  }
}

.actions {
  display: flex;
  justify-content: center;
}
