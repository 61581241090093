@import '@carnica/themes/styles/media/media.pcss';

.search-container {
  position: relative;
  padding: 20px 0 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background-color: var(--color-background-secondary-invert) !important;

  @media (--desktop) {
    padding: 36px 0 0;
  }
}

.wrapper-section {
  padding: 0 16px;
  box-sizing: border-box;
  width: 100%;

  @media (--tablet) {
    padding: 0 24px;
  }

  @media (--desktop) {
    padding: 0 40px;

    &__fixed-bottom {
      padding: 0;
      box-sizing: border-box;
    }
  }

  &__fixed-bottom {
    padding: 0;
    box-sizing: border-box;
  }
}
