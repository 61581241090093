.store {
  &-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: var(--space-3xl);
  }

  &-qr {
    padding: 0 24px 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    max-width: 200px;
    gap: var(--space-l);
  }

  &-image {
    max-width: 100%;
  }
}
