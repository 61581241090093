@import '@carnica/themes/styles/media/media.pcss';

.chips {
  position: relative;
  padding: var(--space-s) var(--space-m);
  display: block;
  width: fit-content;
  border-radius: 44px;
  background-color: var(--color-background-primary);

  @media (width >= 481px) {
    background-color: var(--color-background-secondary);
  }

  & > span {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &:hover {
    opacity: 1;
  }

  &__badge {
    position: absolute;
    top: -4px;
    right: 0;
    padding: 0 var(--space-2xs);
    border-radius: 40px;
    background-color: var(--color-brand-primary);
  }
}
