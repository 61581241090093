@import '@carnica/themes/styles/media/media.pcss';

.block-wrapper {
  padding-right: 8px;
  padding-left: 8px;

  @media (width > 480px) {
    padding-right: 40px;
    padding-left: 40px;
  }

  @media (width > 1025px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  @media (width >= 1100px) {
    padding-right: 66px;
    padding-left: 66px;
  }

  @media (--breakpoint-1280) {
    padding-right: 120px;
    padding-left: 120px;
  }
}
