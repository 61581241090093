@import '@carnica/themes/styles/media/media.pcss';

.container {
  margin: 4px;

  @media (width > 480px) {
    margin: 0 40px;
  }

  @media (--breakpoint-1024) {
    margin: 0 72px;
  }

  @media (width >= 1025px) {
    margin: 0 4px;
  }

  @media (--breakpoint-1280) {
    margin: 0 auto;
    max-width: 1200px;
  }
}
