@import '@carnica/themes/styles/media/media.pcss';

.error {
  margin-top: 36px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;

  @media (--desktop) {
    margin-top: 112px;
  }

  &-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    width: 320px;

    &__text {
      margin-top: 24px;
      box-sizing: border-box;
    }
  }
}
