@import 'styles/media.pcss';

@keyframes opacity-animation {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }
}

@define-mixin skeleton-animation {
  animation: opacity-animation 1.4s ease-in infinite;
}

.wrapper {
  padding: var(--space-3xl) 0 var(--space-2xl);
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: var(--color-background-secondary);

  @media (--tablet) {
    padding: var(--space-3xl);
    background-color: var(--color-background-primary);
  }
}

.component {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media (width >=1025px) {
    align-items: flex-start;
  }
}

.title {
  @mixin skeleton-animation;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  width: 70%;
  height: 28px;
  border-radius: 2px;
  background: var(--background-tertiary, #e2e6ed);
  @media (--tablet) {
    margin-right: initial;
    margin-left: initial;
  }
}

.label {
  @mixin skeleton-animation;
  margin-right: auto;
  margin-bottom: var(--space-l);
  margin-left: auto;
  width: 160px;
  height: 12px;
  border-radius: 2px;
  background: var(--background-tertiary, #e2e6ed);
  @media (--tablet) {
    margin-right: initial;
    margin-left: initial;
  }
}

.chips {
  @mixin skeleton-animation;
  width: 96px;
  height: 44px;
  border-radius: 44px;
  background: var(--background-tertiary, #e2e6ed);
}

.box {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  justify-content: center;
  @media (--tablet) {
    justify-content: flex-start;
  }
}

.card {
  position: relative;
  margin-bottom: var(--space-3xl);
  padding: var(--space-l) var(--space-l) 36px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
  border-radius: 20px;
  background-color: var(--color-background-secondary-invert);

  &__title {
    @mixin skeleton-animation;
    margin-bottom: var(--space-xs);
    width: 80%;
    height: 12px;
    border-radius: 2px;
    background: var(--background-tertiary-invert, #28303f);
  }

  &__subtitle {
    @mixin skeleton-animation;
    margin-bottom: var(--space-3xl);
    width: 60%;
    height: 12px;
    border-radius: 2px;
    background: var(--background-tertiary-invert, #28303f);
  }

  &__info1 {
    @mixin skeleton-animation;
    margin-bottom: var(--space-xs);
    width: 108px;
    height: 32px;
    border-radius: 50px;
    background: var(--background-tertiary-invert, #28303f);
  }

  &__info2 {
    @mixin skeleton-animation;
    margin-bottom: 20px;
    width: 200px;
    height: 28px;
    border-radius: 2px;
    background: var(--background-tertiary-invert, #28303f);
  }

  &__info3 {
    @mixin skeleton-animation;
    margin-bottom: 96px;
    width: 120px;
    height: 12px;
    border-radius: 2px;
    background: var(--background-tertiary-invert, #28303f);
  }

  &__option {
    margin-bottom: 44px;
    display: flex;
    gap: 8px;
  }

  &__option_icon {
    @mixin skeleton-animation;
    width: 32px;
    height: 32px;
    border-radius: 100px;
    background: var(--background-tertiary-invert, #28303f);
  }

  &__option_title {
    @mixin skeleton-animation;
    margin-bottom: var(--space-xs);
    width: 200px;
    height: 12px;
    border-radius: 2px;
    background: var(--background-tertiary-invert, #28303f);
  }

  &__option_label {
    @mixin skeleton-animation;
    margin-bottom: var(--space-xs);
    width: 160px;
    height: 12px;
    border-radius: 2px;
    background: var(--background-tertiary-invert, #28303f);
  }

  &__label {
    @mixin skeleton-animation;
    margin-bottom: var(--space-xs);
    width: 120px;
    height: 12px;
    border-radius: 2px;
    background: var(--background-tertiary-invert, #28303f);
  }
}
