@import '@carnica/themes/styles/media/media.pcss';

.footer-nav {
  box-sizing: border-box;

  &__wrapper {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 63px;
    border-top: 1px solid var(--color-border-secondary-invert);
    background-color: var(--color-background-secondary-invert);

    @media (--desktop) {
      overflow: hidden;
      border-bottom-right-radius: 24px;
      border-bottom-left-radius: 24px;
    }
  }

  &__button {
    padding-left: 16px;
    box-sizing: border-box;
    height: 100%;

    @media (--tablet) {
      padding-left: 24px;
    }

    span {
      transition:
        color 500ms ease-in-out,
        background-color 500ms ease-in-out;

      &:first-child {
        color: var(--color-text-primary-invert);
      }
    }
  }
}

@media (--not-touchscreen) {
  .footer-nav {
    &__button:hover {
      span {
        &:first-child {
          color: var(--color-text-secondary-invert);
        }

        &:nth-child(2) {
          background-color: var(--color-text-secondary-invert) !important;
        }
      }
    }
  }
}
