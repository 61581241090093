@import '@carnica/themes/styles/media/media.pcss';

.brands {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  width: 100%;
  grid-gap: 24px;

  @media (width <= 480px) {
    grid-gap: 4px;
  }
}
