@import '@carnica/themes/styles/media/media.pcss';

.block-chips {
  position: relative;
  margin-top: 24px;
  display: flex;
  overflow-x: scroll;
  box-sizing: border-box;

  @media (--tablet) {
    overflow: hidden;
    max-height: 96px;
  }

  @media (--desktop) {
    max-height: 48px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
