@import '@carnica/themes/styles/media/media.pcss';

.anchor {
  scroll-snap-margin-top: 60px;
  scroll-margin-top: 60px;

  @media (--tablet) {
    scroll-snap-margin-top: 80px;
    scroll-margin-top: 80px;
  }
}
