@import '@carnica/themes/styles/media/media.pcss';

.help-title {
  padding: 0 20px;

  @media (width > 480px) and (width <= 768px) {
    padding: 0 40px;
  }

  @media (--tablet) {
    padding: 0;
  }
}
