@import '@carnica/themes/styles/media/media.pcss';

.brand {
  position: relative;
  margin: 0;
  padding: 16px 12px;
  display: flex;
  align-items: center;
  flex-basis: 130px;
  height: 120px;
  border-radius: 20px;
  background-color: var(--color-background-primary);
  background-repeat: no-repeat;
  background-position: center center;
  transition: transform 0.6s ease-in-out;
  cursor: pointer;

  @media (--not-touchscreen) {
    &:hover {
      transform: scale(1.02);
    }

    &:not(:hover) {
      transition: transform 0.3s ease-in-out;
      transform: scale(1);
    }
  }

  @media (width <= 480px) {
    border-radius: 24px;
    background-color: var(--color-background-secondary);
  }
}

.link {
  position: absolute;
  inset: 0;
  font-size: 0;
}
