@import '@carnica/themes/styles/media/media.pcss';

.sidebar {
  display: none;
  box-sizing: border-box;

  @media (--desktop) {
    display: unset;
  }

  & > * {
    box-sizing: border-box;
  }
}

.search-sidebar {
  box-sizing: border-box;
  width: 440px;
  background-color: transparent;

  @media (--desktop) {
    background-color: var(--color-background-secondary-invert);
  }
}

.modal {
  position: absolute;
  top: 44px;
  z-index: 12;
  padding: 24px 0;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  height: 210px;
  border-radius: 24px;
  background-color: var(--color-background-secondary-invert);
  transition: height 0.5s;

  &--result {
    padding-bottom: 0;
    height: 554px;
  }
}
