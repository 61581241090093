.banner {
  position: relative;
  padding: 60px 24px 24px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 540px;
  background-repeat: no-repeat;
  background-size: cover;

  &__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: var(--space-l);
  }

  &__actions {
    position: absolute;
    bottom: 68px;
    left: 24px;
  }

  &__legal {
    display: flex;
    align-items: flex-end;
    flex: 1;

    @media (width > 480px) {
      position: relative;
      bottom: 40px;
    }
  }
}
