.button {
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      .icon {
        transition: transform 0.6s ease-in-out;
        transform: scale(1.05);

        > span {
          opacity: 0.7;
          transition: opacity 0.6s ease-in-out;
        }
      }

      .button-content {
        opacity: 0.7;
        transition:
          opacity,
          transform 0.6s ease-in-out;
        transform: scale(1.03);
      }
    }
  }

  &-wrap {
    display: flex;
    align-items: center;
    gap: var(--space-xs);

    &.text-color {
      &--primary {
        color: var(--color-text-primary);
      }

      &--secondary {
        color: var(--color-text-secondary);
      }

      &--tertiary {
        color: var(--color-text-tertiary);
      }
    }

    &.text-weight {
      &--normal {
        font-weight: var(--font-weight-regular);
      }

      &--medium {
        font-weight: var(--font-weight-medium);
      }

      &--light {
        font-weight: var(--font-weight-light);
      }

      &--bold {
        font-weight: var(--font-weight-bold);
      }
    }
  }

  &-content {
    opacity: 1;
    transition:
      opacity,
      transform 0.3s ease-in-out;
    transform: scale(1);
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  transition: transform 0.3s ease-in-out;
  transform: scale(1);
  transform-origin: center center;

  > span {
    display: block;
    height: auto;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  &-fill {
    &--primary {
      background-color: var(--color-background-primary);
    }

    &--secondary {
      background-color: var(--color-background-secondary);
    }
  }
}
